
import { computed, defineComponent, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import "@fullcalendar/core/vdom";
import locale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";

import CSpinner from "@/components/spinner.vue";
import FFullCalendar, { CalendarApi, CalendarOptions } from "@fullcalendar/vue3";
import { formatPhone, addMoneyMask, formatDateEn, formatDate } from "@/utils/formatters";

const VProcedureEstablishment = defineComponent({
  name: "VProcedureEstablishment",
  components: { CSpinner, FFullCalendar },
  setup() {
    useHead({ title: "Estabelecimentos | obmed" });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.commit("setAllEstablishment", {});

    const allEstablishment = computed(() => store.state.establishment.list);
    const fullCalendar = ref<{ $el: HTMLDivElement; getApi: () => CalendarApi } | null>(null);
    const dates = computed(() => Object.keys(allEstablishment.value));
    const selectedDate = ref<string | null>(route.query.date?.toString() || null);
    const selectedDateEl = ref<HTMLElement | null>(null);

    const loading = reactive({ list: false });

    function handleEventDateClick(event: DateClickArg) {
      if (event.dayEl.classList.contains("fc-day-disabled")) return;

      selectedDate.value = formatDate(event.dateStr);

      selectedDateEl.value?.classList.remove("selected-date");
      selectedDateEl.value = event.dayEl;
      selectedDateEl.value?.classList.add("selected-date");
    }

    function handleMountedCalendar() {
      const stopWatch = watchEffect(() => {
        if (!dates.value.length) return;
        const dayCells = fullCalendar.value?.$el.querySelectorAll<HTMLElement>("td[data-date]");

        dayCells?.forEach((dayCell) => {
          if (dates.value.includes(formatDate(dayCell.dataset.date))) return;

          dayCell.classList.add("fc-day-disabled");
          dayCell.setAttribute("disabled", "");
        });
      });

      if (dates.value.length) stopWatch();
      handleDateSelection();
    }

    function handleViewDidMount() {
      handleMountedCalendar();
      fullCalendar.value?.$el.querySelector(".fc-prev-button")?.addEventListener("click", handleMountedCalendar);
      fullCalendar.value?.$el.querySelector(".fc-next-button")?.addEventListener("click", handleMountedCalendar);
      fullCalendar.value?.$el.querySelector(".fc-today-button")?.addEventListener("click", handleMountedCalendar);
    }

    function handleDateSelection() {
      if (!selectedDate.value || !fullCalendar.value?.$el) return;

      setTimeout(() => {
        selectedDateEl.value =
          fullCalendar.value?.$el.querySelector(`[data-date="${formatDateEn(route.query.date)}"]`) || null;
        selectedDateEl.value?.classList.add("selected-date");
      }, 200);
    }

    const calendarOptions: CalendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      initialDate: formatDateEn(route.query.date?.toString()) || formatDateEn(new Date().toDateString()),
      validRange: { start: new Date(), end: new Date().setDate(new Date().getDate() + 180) },
      dateClick: handleEventDateClick,
      viewDidMount: handleViewDidMount,
      locale,
    };

    async function getAllEstablishmentByProcedure() {
      loading.list = true;
      await store.dispatch("getAllEstablishmentByProcedure", {
        cd_procedimento: String(route.query.pe),
      });
      loading.list = false;

      if (!selectedDate.value) selectedDate.value = String(Object.keys(allEstablishment.value)[0]);
    }

    watch(selectedDate, (date) => router.replace({ query: { ...route.query, date } }));

    if (route.query.pe) getAllEstablishmentByProcedure();
    else router.back();

    onMounted(handleDateSelection);

    return {
      ...{ allEstablishment, fullCalendar, selectedDate, loading, calendarOptions },
      ...{ formatPhone, addMoneyMask },
    };
  },
});

export default VProcedureEstablishment;
